<template>
    <div class="approveDetail">
        <div class="approveDetail-wrap">
            <div class="approveDetail-wrap-h2">
                <span>江雪松_202111122_费用报销</span>
                <van-tag style="margin-left: 10px" type="success">审核中</van-tag>
            </div>
            <div class="approveDetail-wrap-title">11-16 江雪送</div>
            <div class="approveDetail-wrap-title"><van-icon name="label-o" /> 费用报销</div>
            <div class="approveDetail-wrap-content">
                <div>缉毒警察角度讲迟到就考虑到酷酷的了看得出来的课程大V而快乐我看的进出口了记错了卡杀出去</div>
                <div class="approveDetail-wrap-img">
                    <img src="http://i01.wedots.cn/2021/04/14/8c0574f182939d8b735b1a633c8f76c3.jpg?imageMogr2/thumbnail/400x400!/quality/75/format/png">
                </div>
            </div>
        </div>
        <div class="approveDetail-wrap">
            <div class="approveDetail-wrap-nav">审批人</div>
            <div class="approveDetail-wrap-avatarList">
                <div v-for="item in 9" :key="item" class="approveDetail-wrap-avatar">
                    <avatar src="http://i01.wedots.cn/2/2018/5/3BQ2Iovw3DA.jpeg?imageMogr2/thumbnail/180x180!/quality/75/format/png" color="#fff" :rounded="false" :size="52"></avatar>
                    <div style="text-align:center;margin-top:3px">江雪松</div>
                </div>
            </div>
        </div>
        <div class="approveDetail-wrap">
            <div class="approveDetail-wrap-nav">相关人</div>
            <div class="approveDetail-wrap-avatarList">
                <div v-for="item in 9" :key="item" class="approveDetail-wrap-avatar">
                    <avatar src="http://i01.wedots.cn/2/2018/5/3BQ2Iovw3DA.jpeg?imageMogr2/thumbnail/180x180!/quality/75/format/png" color="#fff" :rounded="false" :size="52"></avatar>
                    <div style="text-align:center;margin-top:3px">江雪松</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Tag } from 'vant'
    import Avatar from 'vue-avatar'
    import Loadings from '@/components/loading'
    export default {
        name:'approveDetail',
        components: {
            [Tag.name]: Tag,
            [Avatar.name]: Avatar,
            loadings:Loadings,
        },
    }
</script>

<style lang="less">
.approveDetail{
    &-wrap{
        background: #fff;
        padding: 10px;
        margin-bottom: 10px;
        &-h2{
            color: #333;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
        }
        &-nav{
            color: #333;
            font-size: 15px;
            margin-bottom: 10px;
        }
        &-title{
            color: #666;
            font-size: 12px;
            margin-bottom: 6px;
            display: flex;
            align-items: center;
        }
        &-content{
            color: #666;
            font-size: 14px;
            margin-bottom: 6px;
        }
        &-img{
            margin-top: 10px;
            img{
                width: 100%;
            }
        }
        &-avatarList{
            display: flex;
            flex-wrap: wrap;
        }
        &-avatar{
            font-size: 12px;
            color: #666;
            margin: 5px 5px 5px 0;
        }
    }
}
</style>